import React, { useEffect, useState } from 'react';
import './App.css';
import BGHomeL from './assets/bghomel.svg'
import BGHomeR from './assets/bghomer.svg'
import move from './assets/moveterra.svg'
import instagram from './assets/insta.svg'
import whatsapp from './assets/whats.svg'
import logofooter from './assets/logofooter.svg'
import logowhite from './assets/logowhite.svg'
import action1 from './assets/actionwhite.svg'
import desk1 from './assets/desk1.svg'
import fortalece from './assets/fortalece.svg'
import fortaleceimg from './assets/fortaleceimg.png'
import fortalecemob from './assets/fortalecemobimg.png'
import fortalecemobtxt from './assets/fortalecemob.svg'
import bg1 from './assets/bg1.png'
import bgmob1 from './assets/bghomemob.png'
import mapatxt from './assets/mapatxt.svg'
import mapabanner from './assets/mapabannerdesk.svg'
import mapa from './assets/mapa.svg'
import mapamob from './assets/mapamob.svg'
import mapabannermob from './assets/mapabanner.svg'
import mapamobtxt from './assets/mapamobtxt.svg'
import juntodesktxt from './assets/juntodesktxt.svg'
import juntodesk from './assets/juntoimg.png'
import juntodeskicon from './assets/juntoimgicon.svg'
import juntomobtxt from './assets/juntomobtxt.svg'

import mulher from './assets/mulher.png'
import sua from './assets/mulherdesktxt.svg'
import suamob from './assets/mulhermobtxt.svg'

import produzotxt from './assets/produzodesk.svg'
import produzo from './assets/produzo.png'
import produzoicon from './assets/donaicon.svg'

import produzomob from './assets/produzomob.svg'
import dona from './assets/dona.svg'

import mundo from './assets/mundo.svg'
import mundotexto from './assets/mundotexto.svg'
import bg from './assets/bg.png'

import mob2 from './assets/mob2.svg'

import mundomob from './assets/mundomob.png'
import mundomob2 from './assets/mundo2mob.svg'
import ReactPixel from 'react-facebook-pixel';

function App() {
  const [step, setStep] = useState(1)
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get('utm_source');
  const utm_mediume  = queryParams.get('utm_medium');
  const utm_campaigne = queryParams.get('utm_campaign');
  useEffect(() => {
    ReactPixel.init('107325776587536');
    ReactPixel.pageView();
  },[])
  let url = '?utm_campaign=doacaoregular&utm_medium=mulheresdaterra&utm_source=website'
  if (utm_source !== null) 
    url = `?utm_source=${utm_source}&utm_medium=${utm_mediume}&utm_campaign=${utm_campaigne}`
   return (
    <div className="App w-full max-w-100vw overflow-x-hidden">
      <div className="bg-[#fff] flex flex-col">
        <div className="fixed top-0 w-full flex flex-col md:flex-row justify-between items-center bg-[#16332F] py-6 z-[100]">
          <div className="container flex flex-row-reverse md:flex-row md:flex-row mx-auto justify-center md:justify-between px-5 md:px-10">            
            <img src={action1} alt="logo" className="w-6/12 md:w-auto flex"/>
            <img src={logowhite} alt="logo" className="w-6/12 md:w-auto"/>            
            <div className="md:flex  flex-row hidden justify-center items-center relative">
            </div>
            <div className="md:flex  flex-row hidden justify-center items-center  z-50">
              <a  onClick={(e) => { ReactPixel.track( 'Purchase', {value: 0.00,currency: 'BRL'}); }} target="_blank" className='bg-[#EA6C58] md:relative md:top-0 z-50 flex text-[#FEF9EA] font-bold shadow-xl text-2xl uppercase text-center px-24 py-3' href={`https://seguro.actionaid.org.br/mulheresdaterra/single_step/${url}`} rel="noreferrer">DOE AGORA</a>
            </div>            
            <a onClick={(e) => { ReactPixel.track( 'Purchase', {value: 0.00,currency: 'BRL'}); }} target="_blank" className='bg-[#EA6C58] w-[90vw] md:w-auto fixed md:absolute flex flex-col justify-center items-center md:hidden bottom-[20px] md:top-0 z-50 flex text-[#FEF9EA] font-bold shadow-2xl text-2xl uppercase text-center px-8 py-3' href={`https://seguro.actionaid.org.br/mulheresdaterra/single_step/${url}`} rel="noreferrer">DOE AGORA</a>
          </div>
        </div>
        <div className="relative mt-[120px] h-[475px] md:h-[85vh] hidden md:flex bg-right-top bg-no-repeat bg-cover w-full"style={{ backgroundPosition: 'right top -100px', backgroundImage: `url(${bg1})` }}> 
          <img src={desk1} className="absolute hidden md:flex top-0 left-0 h-[100%]" alt="desk"/>
        </div>          
        <div className="relative mt-[80px] max-h-[90vh] md:mt-0 flex md:hidden bg-top-center "> 
          <img src={bgmob1} className="w-[100vw]" alt='mob'/>
        </div>        
        
        <div className='flex px-4 flex-col my-6 md:hidden justify-center items-center w-full relative '>                        
          <img src={mob2} className="" alt='mob'/>
            <iframe className='h-64 w-full md:w-9/12 mt-2 md:h-[50vh] xl:h-[60vh] z-50' src="https://www.youtube.com/embed/6Xxy-DeeMEA?controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
        <section className='xl:h-[95vh] overflow-hidden py-20 hidden md:flex  w-full flex flex-col justify-end md:justify-between bg-[#fff] container-fluid p-0 relative'>          
          <div className='w-full flex justify-center h-auto mb-10 z-40'>
            <img src={move} className="w-[50vw]" alt=""/>
          </div>
          <div className="hidden absolute h-[60vh] xl:ml-0 z-40 bottom-0 xl:flex bg-left-bottom bg-contain bg-no-repeat w-[640px]"style={{ backgroundImage: `url(${BGHomeL})` }}> </div>      
          <div className='container-fluid w-full flex items-center justify-center mx-auto'><iframe className='h-64 w-full md:w-9/12 mt-2 md:h-[50vh] xl:h-[60vh] z-50' src="https://www.youtube.com/embed/6Xxy-DeeMEA?controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>              </div>
          <div className="hidden absolute h-[100%] xl:ml-0 z-40 bg-right xl:flex right-0  bg-contain bg-no-repeat w-[540px] flex justify-end items-center">
            <img src={BGHomeR} className="h-[50vh]" alt=""/>
          </div>          
        </section>

        <section className='md:container md:mx-auto md:p-10 min-h-[300px] flex flex-col md:flex-row justify-center items-center bg-[#fff]'>
          <img src={fortaleceimg} className="hidden max-w-[600px] w-6/12 md:flex w-full" alt="Elas têm pouco espaço na participação das decisões e precisam encarar muitos desafios. Alguns vem da terra, como a falta de chuva ou a ausência de melhores condições para plantar, colher e vender sua produção. Outros vêm da própria vizinhança: o machismo ainda persiste, o que dá margem para exclusão social, agressão física e violência doméstica."/>
          <img src={fortalece} className="hidden min-w-[690px] w-6/12 md:flex w-full ml-[-100px]" alt=""/>
          <img src={fortalecemob} className="flex md:hidden w-full" alt=""/>
          <img src={fortalecemobtxt} className="flex md:hidden w-full" alt=""/>
        </section>
        <section className='xl:container md:mx-auto md:p-10 min-h-[300px] pt-10 flex flex-col md:flex-row justify-center items-center bg-[#fff]'>
            <div className='hidden md:flex flex-col justify-center items-center w-full md:w-1/2'>
              <img src={mapatxt} className="hidden md:flex w-full" alt=""/>
              <img src={mapabanner} className="hidden md:flex w-full" alt=""/>
            </div>
            <div className='hidden md:flex flex-col justify-center items-center w-full md:w-1/2'>
              <img src={mapa} className="hidden md:flex w-full" alt=""/>
            </div>
            <img src={mapamob} className="flex md:hidden w-full" alt=""/>
            <img src={mapamobtxt} className="flex md:hidden w-full p-6" alt=""/>
            <img src={mapabannermob} className="flex md:hidden w-full" alt=""/>
        </section>
        <section className='min-h-[300px] flex flex-col md:flex-row w-full bg-[#16332F]'>
          <a href="https://seguro.actionaid.org.br/mulheresdaterra/single_step/?utm_campaign=doacaoregular&utm_medium=mulheresdaterra&utm_source=website" target='_blank'><img src={juntomobtxt} className="flex mb-6 md:hidden w-full" alt=""/></a>
          <img src={juntodesk} className="flex mb-6 md:hidden w-full" alt=""/>
          <div className='hidden md:flex flex-col py-6 justify-center items-center w-full md:w-1/2'>
            <a href="https://seguro.actionaid.org.br/mulheresdaterra/single_step/?utm_campaign=doacaoregular&utm_medium=mulheresdaterra&utm_source=website" target='_blank'>
              <img src={juntodesktxt} className="hidden md:flex w-full  px-2" alt=""/>
            </a>
          </div>
          <div className='hidden md:flex relative flex-col justify-center items-center w-full md:w-1/2'>
            <img src={juntodesk} className="hidden md:flex w-full pr-10" alt=""/>
            <img src={juntodeskicon} className="hidden absolute top-0 w-20 left-0 md:flex w-full" alt=""/>
          </div>
        </section>

        <section className='xl:h-[95vh] md:my-10 w-full bg-[#91C883] flex md:flex-row flex-col'>
          <div className='w-[15vw] xl:flex hidden h-full bg-[#16332F]'>
            <img src={mulher} className="object-cover h-[100%] hidden md:flex" alt=""/>
          </div>
          <div className='xl:w-[75vw] flex-col hidden md:flex h-full p-20'>
            <img src={sua} alt="" className="h-full"/>
          </div>
          <div className='w-full p-6 xl:p-20 md:hidden relative'>
            <img src={suamob} alt="" className="w-full"/>
          </div>
          <div className='w-[10vw] md:flex hidden h-full bg-[#16332F]'>
          </div>
          
        </section> 


        <section className='xl:h-[95vh] md:my-10 w-full bg-[#FEF9EA] flex md:flex-row flex-col'>
          <div className='xl:w-[90vw] flex-row hidden md:flex h-full'>
            <img src={produzotxt} alt="" className="w-6/12"/>
            <div className='md:flex hidden w-6/12 h-full relative'>
              <img src={produzo} alt="" className="object-cover"/>
              <img src={produzoicon} alt="" className="absolute right-0 w-40"/>
            </div>
          </div>
          <div className='w-full xl:p-20 md:hidden relative'>
            <img src={produzomob} alt="" className="w-full"/>
            <div className='flex md:hidden w-full h-full relative'>
              <img src={produzo} alt="" className="object-cover"/>
              <img src={produzoicon} alt="" className="absolute right-0 w-40"/>
            </div>
          </div>
          <div className='w-[10vw] md:flex hidden h-full bg-[#833530]'>
          </div>
        </section>

        <section className='w-full hidden md:flex min-h-[400px] items-center bg-cover justify-center' style={{backgroundImage: `url(${bg})`}}>
            <div className='container mx-auto max-w-[600px]'>
              <img src={mundo} alt="" className='w-full'/>
            </div>
        </section>
        <section className='container mx-auto min-h-[300px] items-center justify-center w-full hidden md:flex flex-col md:flex-row bg-[#833530] mb-10'>
          <img src={mundotexto} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
        </section>

        <section className='md:my-10 min-h-[300px] items-center justify-center w-full flex md:hidden flex-col md:flex-row bg-[#fff]'>          
          <div className='w-full h-full flex md:hidden items-center justify-center'>
            <img src={mundomob2} alt="" className='w-full'/>
          </div>
          <div className='w-full h-full flex md:hidden items-center justify-center'>
            <img src={mundomob} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
          </div>
        </section>


        {/*<section className='min-h-[30vh] md:h-[95vh] w-full bg-[#fff]'>
          <div className='w-full h-full flex justify-start items-end md:items-center relative'>
            <div className='hidden md:flex w-[90vw] h-[80vh] bg-[#fff] flex-col text-left absolute left-0 items-center'>
              <img src={m2} className='absolute left-0 w-auto h-full object-contain' alt= ""/>
            </div>
            <div className='flex md:hidden bg-[#fff] flex-col text-left block w-full mt-20 left-0 items-center'>
              <img src={m3} className='w-full h-full object-cover' alt= ""/>
            </div>
          </div>
        </section>
        <section className='min-h-[300px] flex md:flex-row w-full bg-[#EA6C58]'>
          <div className='w-full h-full flex md:hidden justify-center'>
            <img src={graphm} alt="" className='h-auto w-full'/>
          </div>
          <div className='w-6/12 p-4 h-full md:flex hidden justify-center'>
            <img src={agro} alt="" className='h-auto w-full'/>
          </div>
          <div className='w-6/12 p-4 h-full md:flex flex-col hidden justify-center'>
            <img src={agroimg} alt="" className='h-auto w-full'/>
            <a href="#" className='bg-[#16332F] text-[#FEF9EA] text-center p-4 shadow-lg mt-4'>Quer ajudar? Clique aqui.</a>
          </div>
        </section>
        <section className='xl:h-[95vh] w-full bg-[#FFF]'>
          <div className='container hidden md:flex justify-center py-6 mx-auto'>
            <img src={map} alt="Sabemos que são elas as que
mais se destacam no cuidado com a natureza e com as pessoas, por isso precisamos do seu apoio para potencializar o poder transformador das mulheres que, mesmo com dificuldade, cresceram 38% na agricultura brasileira.
" className='h-auto w-full'/>
          </div>
          <div className='container md:hidden flex justify-center p-6 mx-auto'>
            <img src={mapmob} alt="" className='h-auto w-full'/>
          </div>
        </section>
        <section className='w-full bg-[#FFF]'>
          <div className='container md:hidden flex justify-center mx-auto'>
            <img src={forcamob2} alt="" className='w-full'/>
          </div>          
          <div className='container md:hidden flex justify-center mx-auto mb-10'>
            <img src={forcamob} alt="" className='w-full'/>
          </div>
          <div className='container hidden md:flex justify-center mx-auto'>
            <img src={forca} alt="Elas precisam encarar muitos desafios, 
além da dificuldade de participação nas decisões.
Alguns vêm da terra, como a falta de chuva ou a
 ausência de melhores condições para plantar, 
colher e vender sua produção.
Outros vêm da própria vizinhança: o machismo 
ainda persiste, o que dá margem para exclusão social, agressão física e violência doméstica.
" className='w-full'/>
          </div>
        </section>
        <section className='xl:h-[95vh] w-full bg-[#91C883] flex md:flex-row flex-col'>
          <div className='w-[10vw] md:flex hidden h-full bg-[#16332F]'>
          </div>
          <div className='w-[80vw] flex-col hidden md:flex h-full p-20'>
            <img src={sua} alt="Você pode garantir que mais mulheres possam mudar suas próprias vidas 
e suas comunidades, por meio de soluções que preservam a natureza.
Para fazer parte dessa ideia, basta um gesto. Tornar-se uma doadora agora mesmo. Juntas, podemos transformar a vida de mulheres que mudam suas realidades no campo.
Apoie hoje mesmo Mulheres da Terra para trazer a transformação através da agroecologia. 
Doe agora!" className="h-full"/>
            <div className='bg-[#EA6C58] p-6 text-[#FEF9EA] shadow-lg flex justify-center'>
               <a href="#" className='w-full font-bold text-center text-xl'>Doe e ajude mais</a>
            </div>
          </div>
          <div className='w-full p-20 md:hidden relative'>
            <img src={suamob} alt="" className="w-full"/>
            <div className='bg-[#EA6C58] p-6 text-[#FEF9EA] shadow-lg flex justify-center absolute mt-10 w-[70vw]'>
               <a href="#" className='w-full font-bold text-center text-xl'>Doe e ajude mais</a>
            </div>
          </div>
          <div className='w-[10vw] md:flex hidden h-full bg-[#16332F]'>
          </div>
          
        </section>
        <section className='md:my-10 min-h-[300px] items-center justify-center w-full flex flex-col md:flex-row bg-[#FEF9EA]'>
          <div className='w-full p-4 h-full flex md:hidden items-center justify-center'>
            <img src={textoligiamob} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
          </div>
          <div className='w-full h-full flex md:hidden items-center justify-center'>
            <img src={ligiamob} alt="" className='w-full'/>
          </div>
          <div className='w-full h-full p-10 flex md:hidden items-center justify-center'>
            <img src={textoligiamobajude} alt="" className='w-full'/>
          </div>
          <div className='w-6/12 p-4 h-full md:flex hidden items-center justify-center'>
            <img src={textoligia} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
          </div>
          <div className='w-6/12 h-full md:flex flex-col hidden justify-center'>
            <img src={ligia} alt="" className='h-auto w-full'/>
          </div>
        </section>
        <section className='w-full hidden md:flex min-h-[400px] items-center justify-center' style={{backgroundImage: `url(${bg})`}}>
            <div className='container mx-auto'>
              <img src={mundo} alt="" className='w-full'/>
            </div>
        </section>
        <section className='container mx-auto min-h-[300px] items-center justify-center w-full hidden md:flex flex-col md:flex-row bg-[#833530] mb-10'>
          <img src={mundotexto} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
        </section>
        <section className='md:my-10 min-h-[300px] items-center justify-center w-full flex md:hidden flex-col md:flex-row bg-[#833530]'>
          <div className='w-full h-full flex md:hidden items-center justify-center'>
            <img src={mundomob} alt="O que eu produzo eu vendo, troco, até faço doação. Todo mundo quer porque é puro, não tem veneno. É muito bom porque não falta meu trocadinho e isso já é o bastante para garantir o pão, o leite, o lanche para minha neta levar para a escola todo dia. Isso é muito gratificante." className='w-full'/>
          </div>
          <div className='w-full h-full flex md:hidden items-center justify-center'>
            <img src={mundomob2} alt="" className='w-full'/>
          </div>
        </section>*/}
        <footer className='bg-[#91C883] w-full flex-col md:flex-row items-center flex p-10'>
          <div className='flex flex-col md:w-8/12'>
            <h3 className='font-bold text-[#08342F] mb-4 text-2xl'>Siga o Instagram da ActionAid e saiba mais sobre o Mulheres da Terra!</h3>
            <p className='flex flex-row items-center mb-4'>
            <a href="https://www.instagram.com/actionaidbrasil/" className='text-[#08342F] text-xl flex flex-row justify-center'>
              <img src={instagram} alt="" className='w-10 mr-2'/>              
                @actionaidbrasil
              </a>
            </p>
            <p className='flex flex-row items-center'>
            <a href="https://wa.me/?text=https://mulheresdaterra.org.br/" className='text-[#08342F] md:text-xl flex flex-row justify-center items-center'>
              <img src={whatsapp} alt="" className='w-10 mr-2'/>
              Compartilhe o Mulheres da Terra também no WhatsApp
            </a>
            </p>
          </div>
          <div className='flex flex-col md:w-4/12 p-6'>
            <img src={logofooter} alt=""/>
          </div>
        </footer>
      </div>    
    </div>
  );
}

export default App;
